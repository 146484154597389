import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true,
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403'),
    hidden: true,
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    hidden: true,
  },
]
/* remixicon 图标*/
export const asyncRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    meta: {
      title: '首页',
      icon: 'home-4-line',
      affix: true,
    },
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('@/views/index'),
        meta: {
          title: '首页',
          icon: 'home-4-line',
          affix: true,
        },
      },
    ],
  },
  {
    path: '/goods',
    component: Layout,
    redirect: '/goods/list',
    meta: {
      title: '商品管理',
      icon: 'table-fill',
    },
    children: [
      {
        path: 'list',
        name: 'GoodsList',
        component: () => import('@/views/mall/goods/list'),
        meta: {
          title: '商品列表',
          icon: 'list-radio',
        },
      },
      {
        path: 'create',
        name: 'GoodsCreate',
        component: () => import('@/views/mall/goods/create'),
        meta: {
          title: '商品上架',
          icon: 'heart-add-fill',
        },
      },
      {
        path: 'edit/:id',
        component: () => import('@/views/mall/goods/edit'),
        name: 'goodsEdit',
        meta: {
          title: '商品编辑',
          icon: 'heart-add-fill',
          noCache: true
        },
        hidden: true
      },
      {
        path: 'category',
        name: 'Category',
        component: () => import('@/views/mall/category'),
        meta: {
          title: '商品分类',
          icon: 'apps-line',
        },
      },
      {
        path: 'comment',
        name: 'Comment',
        component: () => import('@/views/mall/comment'),
        meta: {
          title: '商品评价',
          icon: 'chat-heart-fill',
        },
      },

    ],
  },
  {
    path: '/order',
    component: Layout,
    redirect: '/order/index',
    meta: {
      title: '订单管理',
      icon: 'secure-payment-fill',
    },
    children: [
      {
        path: 'index',
        name: 'OrderIndex',
        component: () => import('@/views/mall/order/index'),
        meta: {
          title: '订单首页',
          icon: 'currency-line',
        },
      },
      {
        path: 'delivery',
        name: 'OrderDelivery',
        component: () => import('@/views/mall/order/delivery'),
        meta: {
          title: '待发货订单',
          icon: 'indent-increase',
        },
      },
      {
        path: 'list',
        name: 'OrderList',
        component: () => import('@/views/mall/order/list'),
        meta: {
          title: '订单清单',
          icon: 'list-ordered-2',
        },
      },
    ],
  },
  {
    path: '/user',
    component: Layout,
    redirect: '/user/index',
    meta: {
      title: '会员管理',
      icon: 'shield-user-fill',
    },
    children: [
      {
        path: 'index',
        name: 'UserIndex',
        component: () => import('@/views/mall/user/index'),
        meta: {
          title: '顾客信息',
          icon: 'folder-user-fill',
        },
      },
      {
        path: 'recharge',
        name: 'RechargeOrder',
        component: () => import('@/views/mall/user/rechargeOrder'),
        meta: {
          title: '充值订单',
          icon: 'money-cny-circle-fill',
        },
      },
      {
        path: 'wechat',
        name: 'WechatPay',
        component: () => import('@/views/mall/user/wechatPay'),
        meta: {
          title: '微信支付记录',
          icon: 'wechat-pay-fill',
        },
      },
      {
        path: 'wechatRefund',
        name: 'wechatRefund',
        component: () => import('@/views/mall/user/wechatPayRefund'),
        meta: {
          title: '微信退款记录',
          icon: 'refund-fill',
        },
      },
      {
        path: 'black',
        name: 'BlackList',
        component: () => import('@/views/mall/user/blackList'),
        meta: {
          title: '黑名单',
          icon: 'user-forbid-fill',
        },
      },
      {
        path: 'feedback',
        name: 'UserFeedback',
        component: () => import('@/views/mall/user/feedback'),
        meta: {
          title: '用户投诉建议',
          icon: 'feedback-fill',
        },
      },
      {
        path: 'coupon',
        name: 'UserCoupon',
        component: () => import('@/views/mall/coupon/userCoupon'),
        meta: {
          title: '用户优惠券',
          icon: 'coupon-4-fill',
        },
      }
    ],
  },
  {
    path: '/setting',
    component: Layout,
    redirect: '/setting/banner',
    meta: {
      title: '店铺设置',
      icon: 'list-settings-fill',
    },
    children: [
      {
        path: 'banner',
        name: 'Banner',
        component: () => import('@/views/mall/setting/banner'),
        meta: {
          title: '首页轮播图',
          icon: 'flag-line',
        },
      },
      {
        path: 'navBar',
        name: 'NavBar',
        component: () => import('@/views/mall/setting/navbar'),
        meta: {
          title: '首页商品组',
          icon: 'asterisk',
        },
      },
      {
        path: 'notice',
        name: 'Notice',
        component: () => import('@/views/mall/setting/notice'),
        meta: {
          title: '店铺公告',
          icon: 'notification-3-line',
        },
      },
      {
        path: 'article',
        name: 'Article',
        component: () => import('@/views/mall/setting/article'),
        meta: {
          title: '店铺文章',
          icon: 'article-fill',
        },
      },
      {
        path: 'points',
        name: 'PointsSetting',
        component: () => import('@/views/mall/setting/points'),
        meta: {
          title: '积分设置',
          icon: 'footprint-fill',
        },
      },
      {
        path: 'recharge',
        name: 'RechargeSetting',
        component: () => import('@/views/mall/setting/recharge'),
        meta: {
          title: '充值设置',
          icon: 'exchange-cny-fill',
        },
      },
      {
        path: 'help',
        name: 'ShopHelp',
        component: () => import('@/views/mall/setting/help'),
        meta: {
          title: '店铺帮助',
          icon: 'question-answer-fill',
        },
      },
      {
        path: 'shipping',
        name: 'ShippingSetting',
        component: () => import('@/views/mall/setting/shipping'),
        meta: {
          title: '配送设置',
          icon: 'ship-2-fill',
        },
      },
      {
        path: 'coupon',
        name: 'Coupon',
        component: () => import('@/views/mall/coupon'),
        meta: {
          title: '优惠券',
          icon: 'coupon-5-fill',
        },
      },
    ],
  },
  {
    path: '/info',
    component: Layout,
    redirect: '/info/index',
    meta: {
      title: '店铺信息',
      icon: 'home-gear-fill',
    },
    children: [
      {
        path: 'index',
        name: 'InfoIndex',
        component: () => import('@/views/mall/info/index'),
        meta: {
          title: '信息概览',
          icon: 'user-heart-line',
        },
      },
      {
        path: 'person',
        name: 'PersonInfo',
        component: () => import('@/views/mall/info/person'),
        meta: {
          title: '个人信息',
          icon: 'user-settings-line',
          noCache: true
        },
        hidden: true
      },
      {
        path: 'shop',
        name: 'ShopInfo',
        component: () => import('@/views/mall/info/shopInfo'),
        meta: {
          title: '店铺编辑',
          icon: 'store-2-line',
          noCache: true
        },
        hidden: true
      },
    ],
  },
  {
    path: '/wework',
    component: Layout,
    redirect: '/wework/msg',
    meta: {
      title: '企业微信会话存档',
      icon: 'wechat-fill',
    },
    children: [
      {
        path: 'msg',
        name: 'ChatMsg',
        component: () => import('@/views/wework/msg'),
        meta: {
          title: '消息记录',
          icon: 'chat-history-fill',
        },
      },
      {
        path: 'group',
        name: 'ChatGroup',
        component: () => import('@/views/wework/group'),
        meta: {
          title: '群聊',
          icon: 'group-fill',
        },
      },
      {
        path: 'user',
        name: 'PersonInfo',
        component: () => import('@/views/wework/customer'),
        meta: {
          title: '客户列表',
          icon: 'user-follow-fill',
        },
      },
    ],
  },

/*  {
    path: '/vab',
    component: Layout,
    redirect: '/vab/table',
    alwaysShow: true,
    meta: {
      title: '组件',
      icon: 'apps-line',
    },
    children: [
      {
        path: 'table',
        name: 'Table',
        component: () => import('@/views/vab/table'),
        meta: {
          title: '表格',
          icon: 'table-2',
        },
      },
      {
        path: 'icon',
        name: 'Icon',
        component: () => import('@/views/vab/icon'),
        meta: {
          title: '图标',
          icon: 'remixicon-line',
        },
      },
    ],
  },
  {
    path: '/test',
    component: Layout,
    redirect: '/test/test',
    meta: {
      title: '动态路由测试',
      icon: 'test-tube-line',
    },
    children: [
      {
        path: 'test',
        name: 'Test',
        component: () => import('@/views/test'),
        meta: {
          title: '动态路由测试',
          icon: 'test-tube-line',
        },
      },
    ],
  },*/
  {
    path: '/error',
    name: 'Error',
    component: Layout,
    redirect: '/error/403',
    meta: {
      title: '错误页',
      icon: 'error-warning-line',
    },
    children: [
      {
        path: '403',
        name: 'Error403',
        component: () => import('@/views/403'),
        meta: {
          title: '403',
          icon: 'number-3',
        },
      },
      {
        path: '404',
        name: 'Error404',
        component: () => import('@/views/404'),
        meta: {
          title: '404',
          icon: 'number-4',
        },
      },
    ],
  },

  {
    path: '/*',
    redirect: '/404',
    hidden: true,
  },
]
const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
})

export default router
